import {environment} from '../../../environments/environment';

const APIURL = environment;

export class ApiUrl {

  // OTHER
  static GET_RIGHT_EMAIL: string;
  static AVAILABILITY_UPDATES: string;
  static REMOTE_CONFIG: string;
  static GET_USER_IP_ADDRESS: string;
  static CREATE_ORGANIZATION: string;
  static CREATE_PAYMENT_INTENT: string;
  static CREATE_SETUP_INTENT: string;
  static RETRIEVE_SETUP_INTENT: string;
  static GET_BILLING_STATUS: string;
  static GET_CUSTOMER_ALL_PAYMENT_METHODS: string;
  static DETACH_PAYMENT_METHOD: string;
  static GET_INVOICE: string;
  static GET_CUSTOMER: string;
  static CREATE_CHECKOUT_SESSION: string;
  static CREATE_BILLING_SESSION: string;
  static CUSTOMER_SUBSCRIPTION: string;
  static ACCEPT_INVITATION: string;
  static INVITATION_VALID_USER: string;
  static LOGIN_VALID_USER: string;
  static FIND_EMAIL_DNS: string;
  static SET_DEFAULT_PAYMENT_METHOD_TO_CUSTOMER: string;
  static SEND_INVITATION: string;
  static RE_SEND_INVITATION: string;
  static REMOVE_ORGANIZATION_MEMBER: string;
  static CANCEL_INVITATION: string;
  static GET_TRAVEL_USERS_LIST: string;
  static GET_TRAVEL_SELECTED_USER_DETAILS: string;
  static GET_TRAVEL_SELECTED_USER_POINTS_RECORD: string;
  static TRAVEL_USER_POINTS: string;
  static GET_BOOKINGS_LIST: string;
  static GET_AIRLINES_LIST: string;
  static GET_AIRPORTS_LIST: string;
  static GET_AIRPORT_DETAILS: string;
  static GET_SELECTED_AIRLINE_DETAILS: string;
  static GET_SELECTED_BOOKING_DETAILS: string;
  static GET_LOGS: string;
  static GET_REWARDS: string;
  static UPDATE_CUSTOM_REWARD: string;
  static GET_REWARDS_DETAILS: string;
  static GET_SELECTED_LOG_DETAILS: string;
  static GET_FLIGHT_RATING_REVIEWS: string;
  static GET_SELECTED_BOOKING_RATING_REVIEWS: string;
  static GET_FTUE_QUESTIONS: string;
  static GET_COUNTRIES_LIST: string;
  static FTUE_QUESTION: string;
  static SURVEY_INSIGHTS: string;
  static SURVEY_AI_REFINE: string;

  static CREATE_SURVEY: string;
  static SURVEY_ALERTS: string;
  static ARCHIVE_SURVEYS: string;
  static DUPLICATE_SURVEY: string;
  static GET_SURVEY_DETAILS: string;
  static LIST_SURVEYS: string;
  static GET_SURVEY_CATEGORIZE: string;
  static GET_SURVEY_RESPONSES: string;
  static SURVEY_SUGGESTED_QUESTIONS: string;
  static CREATE_SUGGESTED_QUESTION: string;

  static initialize() {
    ApiUrl.GET_RIGHT_EMAIL = APIURL.FareUpThereAPI + 'mailCheck';
    ApiUrl.AVAILABILITY_UPDATES = APIURL.FareUpThereAPI + 'availability-updates';
    ApiUrl.REMOTE_CONFIG = APIURL.FareUpThereAPI + 'pulse/remote-config';
    ApiUrl.GET_USER_IP_ADDRESS = APIURL.FareUpThereAPI + 'ip';
    ApiUrl.ACCEPT_INVITATION = APIURL.FareUpThereAPI + 'pulse/organization/invitation/accept';
    ApiUrl.INVITATION_VALID_USER = APIURL.FareUpThereAPI + 'pulse/organization/invitation/valid/user';
    ApiUrl.LOGIN_VALID_USER = APIURL.FareUpThereAPI + 'pulse/login';
    ApiUrl.FIND_EMAIL_DNS = APIURL.FareUpThereAPI + 'pulse/organization/invitation/email/dns';
    ApiUrl.SET_DEFAULT_PAYMENT_METHOD_TO_CUSTOMER = APIURL.FareUpThereAPI + 'pulse/payment/set-default';
    ApiUrl.SEND_INVITATION = APIURL.FareUpThereAPI + 'pulse/organization/invitation';
    ApiUrl.RE_SEND_INVITATION = APIURL.FareUpThereAPI + 'pulse/organization/invitation/resend';
    ApiUrl.REMOVE_ORGANIZATION_MEMBER = APIURL.FareUpThereAPI + 'pulse/organization/member';
    ApiUrl.CANCEL_INVITATION = APIURL.FareUpThereAPI + 'pulse/organization/invitation/cancel';
    ApiUrl.CREATE_ORGANIZATION = APIURL.FareUpThereAPI + 'pulse/organization';
    ApiUrl.CREATE_PAYMENT_INTENT = APIURL.FareUpThereAPI + 'pulse/payment/create-payment-intent';
    ApiUrl.CREATE_SETUP_INTENT = APIURL.FareUpThereAPI + 'pulse/payment/setup_intents';
    ApiUrl.RETRIEVE_SETUP_INTENT = APIURL.FareUpThereAPI + 'pulse/payment/setup_intent';
    ApiUrl.GET_BILLING_STATUS = APIURL.FareUpThereAPI + 'pulse/billing/status';
    ApiUrl.GET_CUSTOMER_ALL_PAYMENT_METHODS = APIURL.FareUpThereAPI + 'pulse/payment/methods';
    ApiUrl.DETACH_PAYMENT_METHOD = APIURL.FareUpThereAPI + 'pulse/payment/method/detach';
    ApiUrl.CUSTOMER_SUBSCRIPTION = APIURL.FareUpThereAPI + 'pulse/user/subscription';
    ApiUrl.GET_INVOICE = APIURL.FareUpThereAPI + 'pulse/user/invoice';
    ApiUrl.GET_CUSTOMER = APIURL.FareUpThereAPI + 'pulse/customer';
    ApiUrl.CREATE_CHECKOUT_SESSION = APIURL.FareUpThereAPI + 'pulse/payment/create-checkout-session';
    ApiUrl.CREATE_BILLING_SESSION = APIURL.FareUpThereAPI + 'pulse/payment/create-billing-session';
    ApiUrl.GET_TRAVEL_USERS_LIST = APIURL.FareUpThereAPI + 'pulse/meander-travel/users';
    ApiUrl.GET_TRAVEL_SELECTED_USER_DETAILS = APIURL.FareUpThereAPI + 'pulse/meander-travel/user';
    ApiUrl.GET_TRAVEL_SELECTED_USER_POINTS_RECORD = APIURL.FareUpThereAPI + 'pulse/meander-travel/user/points-record';
    ApiUrl.TRAVEL_USER_POINTS = APIURL.FareUpThereAPI + 'pulse/meander-travel/user/points';
    ApiUrl.GET_BOOKINGS_LIST = APIURL.FareUpThereAPI + 'pulse/meander-travel/bookings';
    ApiUrl.GET_AIRLINES_LIST = APIURL.FareUpThereAPI + 'pulse/meander-travel/airlines';
    ApiUrl.GET_AIRPORTS_LIST = APIURL.FareUpThereAPI + 'pulse/airports';
    ApiUrl.GET_AIRPORT_DETAILS = APIURL.FareUpThereAPI + 'pulse/airport';
    ApiUrl.GET_SELECTED_AIRLINE_DETAILS = APIURL.FareUpThereAPI + 'pulse/airline';
    ApiUrl.GET_SELECTED_BOOKING_DETAILS = APIURL.FareUpThereAPI + 'pulse/meander-travel/booking';
    ApiUrl.GET_LOGS = APIURL.FareUpThereAPI + 'pulse/logs';
    ApiUrl.GET_REWARDS = APIURL.FareUpThereAPI + 'pulse/reward';
    ApiUrl.UPDATE_CUSTOM_REWARD = APIURL.FareUpThereAPI + 'pulse/custom-reward';
    ApiUrl.GET_REWARDS_DETAILS = APIURL.FareUpThereAPI + 'pulse/reward/details';
    ApiUrl.GET_SELECTED_LOG_DETAILS = APIURL.FareUpThereAPI + 'pulse/log';
    ApiUrl.GET_FLIGHT_RATING_REVIEWS = APIURL.FareUpThereAPI + 'pulse/flight-ratings-reviews';
    ApiUrl.GET_SELECTED_BOOKING_RATING_REVIEWS = APIURL.FareUpThereAPI + 'pulse/flight-ratings-reviews';
    ApiUrl.GET_FTUE_QUESTIONS = APIURL.FareUpThereAPI + 'pulse/ftue-questions';
    ApiUrl.GET_COUNTRIES_LIST = APIURL.FareUpThereAPI + 'pulse/countries';
    ApiUrl.FTUE_QUESTION = APIURL.FareUpThereAPI + 'pulse/ftue-question';
    ApiUrl.SURVEY_INSIGHTS = APIURL.FareUpThereAPI + 'pulse/insights';
    ApiUrl.SURVEY_AI_REFINE = APIURL.FareUpThereAPI + 'pulse/survey/ai-refine';

    ApiUrl.CREATE_SURVEY = APIURL.FareUpThereAPI + 'pulse/survey';
    ApiUrl.SURVEY_ALERTS = APIURL.FareUpThereAPI + 'pulse/survey/subscribe';
    ApiUrl.ARCHIVE_SURVEYS = APIURL.FareUpThereAPI + 'pulse/survey/archive';
    ApiUrl.DUPLICATE_SURVEY = APIURL.FareUpThereAPI + 'pulse/survey/duplicate';
    ApiUrl.GET_SURVEY_DETAILS = APIURL.FareUpThereAPI + 'pulse/survey/details';
    ApiUrl.LIST_SURVEYS = APIURL.FareUpThereAPI + 'pulse/surveys';
    ApiUrl.GET_SURVEY_CATEGORIZE = APIURL.FareUpThereAPI + 'pulse/survey/categorize-responses';
    ApiUrl.GET_SURVEY_RESPONSES = APIURL.FareUpThereAPI + 'pulse/survey/responses/details';

    ApiUrl.SURVEY_SUGGESTED_QUESTIONS = APIURL.FareUpThereAPI + 'pulse/survey/suggested-questions';
    ApiUrl.CREATE_SUGGESTED_QUESTION = APIURL.FareUpThereAPI + 'pulse/survey/suggested-question';
  }
}

ApiUrl.initialize();
